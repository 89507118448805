
.cp__contact_h1{
    align-items: center;
    text-align: center;
    color: var(--color-secondary);
    font-family: var(--font-family);
}
.cp_contact_p{
    text-align: center;
    font-family: var(--font-family);
    font-weight: bold;
    color: var(--color-text);
}