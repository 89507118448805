.counter{
    padding: 40px;
    font-family: var(--font-family) ;
  }
  
  .counter h1{
    text-align: center;
    color: var(--color-secondary);
    font-size: 60px;
  }
  .cp__countdow-description{
    padding-top: 90px;
    color: #fff;
    justify-content: center;
    text-align: center;
  }
  .counter p, a{
    color: #fff;
    text-align: center;
  
  }
  
  
  .cp__countdown{
    padding: 20px;
    display: flex;
    justify-content: center;
    text-align: center;
    color: white;
  }
  .cp__countdown_days,.cp__countdown_hour,.cp__countdown_minute,.cp__countdown_seconds{
  
    margin:30px;
  }
  .cp__countdown_days-day,.cp__countdown_hour-hour,.cp__countdown_minute-minute,.cp__countdown_seconds-seconds{
    border: 1px solid ;
    border-radius: 10px;
    padding: 10px 20px 10px 20px;
    font-size: 4rem;
  }
  
  @media screen and (max-width: 1050px) {
  
  }
  
  @media screen and (max-width: 700px) {
  
    .cp__countdown_days,.cp__countdown_hour,.cp__countdown_minute,.cp__countdown_seconds{
  
      margin: 10px;
      }
      .cp__countdown{
        padding: 10px;
        display: flex;
        justify-content: center;
        text-align: center;
        color: white;
      }
      .cp__countdown_days-day,.cp__countdown_hour-hour,.cp__countdown_minute-minute,.cp__countdown_seconds-seconds{
        border: 1px solid ;
        border-radius: 10px;
        padding: 10px 20px 10px 20px;
        font-size: 2rem;
      }
  }
  
  @media screen and (max-width: 550px) {
  .cp__countdown_days,.cp__countdown_hour,.cp__countdown_minute,.cp__countdown_seconds{
  
  margin: 10px;
  }
  .cp__countdown{
    padding: 10px;
    display: flex;
    justify-content: center;
    text-align: center;
    color: white;
  }
  .cp__countdown_days-day,.cp__countdown_hour-hour,.cp__countdown_minute-minute,.cp__countdown_seconds-seconds{
    border: 1px solid ;
    border-radius: 10px;
    padding: 10px 20px 10px 20px;
    font-size: 2rem;
  }
  }@media screen and (max-width: 400px) {
    .cp__countdown_days,.cp__countdown_hour,.cp__countdown_minute,.cp__countdown_seconds{
  
      margin: 5px;
      }
      .cp__countdown{
        padding: 0px;
        display: flex;
        justify-content: center;
        text-align: center;
        color: white;
      }
      .cp__countdown_days-day,.cp__countdown_hour-hour,.cp__countdown_minute-minute,.cp__countdown_seconds-seconds{
        border: 1px solid ;
        border-radius: 10px;
        padding: 5px 10px 5px 10px;
        font-size: 1rem;
      }
      .counter h1{
    text-align: center;
    color: #fff;
  }
  .cp__countdown h3{
    font-size: 15px;
  }
  }
  
  