
.cb__giveaway{
    display: flex;
  justify-content: center;
  align-items: center;
}
.cb__giveaway_background{

    align-items: center ;
    justify-content: center;
    text-align: center;
    font-family: var(--font-family) ;
    background-color: var(--color-secondary);
    height: 200px;
    width: 80%;
    border-radius: 12px;
}
.cb_giveaway_text_pepito h1, p{   

}
.cb_giveaway_text__h1 h1{
    background-color: var(--color-secondary);
    padding: 0;
    margin: 0;
}
.cb_giveaway_text__p1 p {
    background-color: var(--color-secondary);
    padding: 0;
    margin: 0;   
}
.cb_giveaway_text__p2 p {
    background-color: var(--color-secondary);
    padding: 0;
    margin: 0; 
}
.cp_giveaway_a_insta{

}
.cp_giveaway_a_insta:hover{
    color: black;
}