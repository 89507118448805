.cp__team {
    padding-top: 50px;
    position: relative;
    font-family: var(--font-family);
}

#cp_team_tittle {
    color: var(--color-secondary);
    text-align: center;
}
#cp_team_tittle_2 {
    color: #fff;
    text-align: center;
}

.cp__team-integrators {
    display: flex;
    justify-content: center;
    padding: 50px;
}
.cp__team-integrators h2:hover {
    color: var(--color-secondary);
    animation: all .3s;
}
.cp__team-integrators_Bepes img,
.cp__team-integrators_Disenyadora img,
.cp__team-integrators_Orasim img,
.cp__team-integrators_NelsonLive img {
    height: 250px;
    width: 250px;
    margin: 30px;
    border-radius: 10px;
}

.cp__team-integrators h2 {
    color: #fff;
    text-align: center;

}

.cp__team-integrators p {
    color: #fff;
    text-align: center;
}
@media screen and (max-width: 1500px) {
    .cp__team-integrators_Bepes img,
    .cp__team-integrators_Disenyadora img,
    .cp__team-integrators_Orasim img,
    .cp__team-integrators_NelsonLive img {
        height: 150px;
        width: 150px;
        margin: 20px;
        border-radius: 10px;
    }
}

@media screen and (max-width: 900px) {
    .cp__team-integrators_Bepes img,
    .cp__team-integrators_Disenyadora img,
    .cp__team-integrators_Orasim img,
    .cp__team-integrators_NelsonLive img {
        height: 150px;
        width: 150px;
        margin: 15px;
        border-radius: 10px;
    }
}

@media screen and (max-width: 990px) {
    .cp__team-integrators {
        display: block;
        justify-content: center;
        padding: 50px;

    }
    .cp__team-integrators_Bepes ,
    .cp__team-integrators_Disenyadora ,
    .cp__team-integrators_Orasim ,
    .cp__team-integrators_NelsonLive {
padding: 25px;
    }
    .cp__team-integrators_Bepes img,
    .cp__team-integrators_Disenyadora img,
    .cp__team-integrators_Orasim img,
    .cp__team-integrators_NelsonLive img {
        height: 150px;
        width: 150px;
        margin: 15px;
        border-radius: 10px;
        margin: auto;
        display: block;
    }
}
@media screen and (max-width: 375px) {

}
