/* 
body{
    margin: 0;
} */
.cp__navbar{
    justify-content: space-between;
    align-items: center;
    z-index: 1000;
     background-color: var(--color-bg);

    /* padding: 1.5rem 6rem; */
}
.cp__navbar-links_logo{
    display: flex;
    color: var(--color-text);
    font-family: var(--font-family);
    flex: 1;
    padding-left: 20px;
}
.cp__navbar-links_logo a{
    font-weight: 700;
    text-decoration: none;
    color: var(--color-text);
    font-size: 16px;
    text-transform: uppercase;
    letter-spacing: 2px;

}
.cp__navbar-links {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
}

.cp__navbar-links_container ul{
    display: flex;
    justify-content: center;
    align-items: center;
}
.cp__navbar-links_container ul li {
    list-style: none;
    margin-left: 20px;
}
.cp__navbar-links_container ul li a{
    text-decoration: none;
    padding: 6px 15px;
    color: var(--color-text);
    font-family: var(--font-family);
}
.cp__navbar-links_container ul li a:hover{
color: var(--color-secondary);
transition: all .3s;
touch-action: manipulation;

}
.cp_navbar-mint{
    padding-right: 20px;
    flex: 1;
    display: flex;
    justify-content: flex-end;
}
.cp_navbar-mint button{
align-items: center;
background-color: transparent;
border: 2px solid var(--color-text);
border-radius: 10px;
cursor: pointer;
font-size: 16px;
height: 35px;
justify-content: center;
letter-spacing: -.8px;
line-height: 24px;
min-width: 140px;
padding: 0 17px;
text-align: center;
text-transform: uppercase;;
transition: all .3s;
touch-action: manipulation;

}
.cp_navbar-mint button a{
    text-decoration: none;
    color: var(--color-text);
}
.cp_navbar-mint button:hover{
    border: 2px solid var(--color-secondary);

}.cp_navbar-mint-menu button{
    align-items: center;
background-color: transparent;
border: 2px solid var(--color-text);
border-radius: 10px;
cursor: pointer;
font-size: 16px;
height: 35px;
justify-content: center;
letter-spacing: -.8px;
line-height: 24px;
min-width: 140px;
padding: 0 17px;
text-align: center;
text-transform: uppercase;;
transition: all .3s;
touch-action: manipulation;
}
.cp_navbar-mint-menu button:hover{
    border: 2px solid var(--color-secondary);
}
.sticky {
    background: rgba(7, 7, 7, 0.2);
    backdrop-filter: blur(10px); 
    width: 100%;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    position: fixed;
    border-bottom: 1px solid #fff ;
  }
.cp__navbar-menu {
    position: relative;

}
.cp__navbar-menu{
    margin-left: 1rem;
    display: none;
    position: relative;
}
.cp__navbar-menu_container{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: var(--color-bg);
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 30px;
    margin-top: 1rem;
    border-radius: 5px;
    box-shadow: 0px 0px 5px var(--color-secondary);
}
.cp__navbar-menu_container a{
    color: #fff;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;

    margin: 0 1rem;
    cursor: pointer;
}

.cp__navbar-menu_container-links{
   
}
.cp__navbar-menu_container  {
    margin: 1rem 0;

}
.cp__navbar-menu_container-links ul li {
    list-style: none; 
    padding: 10px;
    background-color: none ;
}
.cp__navbar-menu_container-links a{
    text-decoration: none;
    color: var(--color-text);
}


@media screen and (max-width: 800px) {
    .cp__navbar-links_container, .cp_navbar-mint {
        display: none;
    }
    .cp__navbar-links_logo{
    /* padding-top: 10px;   */
  }

    .cp__navbar-menu {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 8px;
        padding-right: 20px;
        padding-bottom: 15px;
    }
}

