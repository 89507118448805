.cp__roadmap{
    display: flex;

    margin-top: 30px;
    align-items: center;
}
.cp__roadmap-tittle{
    font-family: var(--font-family);
    text-align: center;
    color: var(--color-text);
}
.cp__roadmap-text{
justify-content: center;
align-items: center;
    font-family: var(--font-family);
    color: var(--color-text);

}
.cp__roadmap-text p {
margin-left: 200px;
width: 700px;
line-height: 30px;
text-align: justify;

}

.cp__roadmap-model{
    padding-left: 400px;
}
.cp__roadmap-model img{
    width: 450px;
    height: 800px;
}

@media screen and (max-width: 1800px) {
    .cp__roadmap{
        display: block;
        justify-content: center;
        align-items: center;
    }
    .cp__roadmap-model{
        padding-left: 0;
    }
    .cp__roadmap-model img{
        display: block;
        margin: auto;
padding: 0;
        width: 350px;
        height: 600px;
    }
    .cp__roadmap-text p{
        display: block;
        margin: auto;

        line-height: 30px;
    text-align: justify;
    width: 400px;
 

    }
}
@media screen and (max-width: 900px) {
    .cp__roadmap{
        display: block;
        justify-content: center;
        align-items: center;
    }
    .cp__roadmap-model{
        padding-left: 0;
    }
    .cp__roadmap-model img{
        display: block;
        margin: auto;
padding: 0;
        width: 250px;
        height: 400px;
    }
    .cp__roadmap-text p{
        display: block;
        margin: auto;

        line-height: 30px;
    text-align: justify;
    width: 200px;
 

    }
}



