@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');
:root {
  --font-family: 'Roboto', sans-serif;
  --color-bg: #131110;
  --color-text: #FFFCF2;
  --color-secondary: #EB5E28;
  --color-terciary: #252422;

}

  body{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  *{
  }