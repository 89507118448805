.container {
    
    color: var(--color-text);
    font-family: var(--font-family);
  }
  .text{
    text-align: center;
  }
  .container {
   padding: 200px;
  }
  
  .faq-drawer {
    margin-bottom: 30px;
  }
  
  .faq-drawer__content-wrapper {
    font-size: 1.25em;
    line-height: 1.4em;
    max-height: 0px;
    overflow: hidden;
    transition: 0.25s ease-in-out;
  }
  
  .faq-drawer__title {
    border-top: var(--color-text) 1px solid;
    cursor: pointer;
    display: block;
    font-size: 1.25em;
    font-weight: 700;
    padding: 30px 0 0 0;
    position: relative;
    margin-bottom: 0;
    transition: all 0.25s ease-out;
  }
  
  .faq-drawer__title::after {
    border-style: solid;
    border-width: 1px 1px 0 0;
    content: " ";
    display: inline-block;
    float: right;
    height: 10px;
    left: 2px;
    position: relative;
    right: 20px;
    top: 2px;
    transform: rotate(135deg);
    transition: 0.35s ease-in-out;
    vertical-align: top;
    width: 10px;
  }
  
  /* OPTIONAL HOVER STATE */
  .faq-drawer__title:hover { 
    color: var(--color-secondary)  ;
  }
  
  .faq-drawer__trigger:checked
    + .faq-drawer__title
    + .faq-drawer__content-wrapper {
    max-height: 350px;
  }
  
  .faq-drawer__trigger:checked + .faq-drawer__title::after {
    transform: rotate(-45deg);
    transition: 0.25s ease-in-out;
  }
  
  input[type="checkbox"] {
    display: none;
  }
  
  @media only screen and (max-width: 600px) {
    .container {
      padding: 80px;
    }
  }

  a{
    text-decoration: none;
    font-weight: bold;
    color: var(--color-secondary);
  }
  #cp_faq_twitter:hover, #cp_faq_instagram:hover, #cp_faq_forms:hover{
    color: var(--color-secondary);
  }