.cp_footer{
    padding-top: 100px;
    display: flex;
    justify-content: center;
    text-align: center;
}
.cp_footer img{
    width: 40px;
    height: 40px;
    color: #fff;
    filter: invert(100%);
    margin: 20px;
    background-color: transparent;
}