.cp__socialmedia{
    padding-bottom: 150px;
    font-family: var(--font-family);

}
.cp__socialmedia h1{
    color: #fff;
    text-align: center;
    padding: 10px;
    font-size: 50px;
}
.cp__socialmedia-svg{
    display: flex;
    justify-content: center;
    text-align: center;

}
.cp__socialmedia img{
    width: 80px;
    height: 80px;
    color: #fff;
    filter: invert(100%);
    margin: 40px;
    background-color: transparent;
}
.cp__socialmedia img:hover{
    width: 80px;
    height: 80px;
    color: #fff;
    transition: all .4s;
    filter: blur(0.9px) invert(100%);
    margin: 40px;
    background-color: transparent;
}

@media screen and (max-width: 1500px) {
    .cp__socialmedia img{
        width: 60px;
        height: 60px;
        color: #fff;
        filter: invert(100%);
        margin: 20px;
        background-color: transparent;
    }
}